import React, { useState, useEffect } from 'react';

const Patients = () => {
    const [patients, setPatients] = useState([]);
    const [latestPatient, setLatestPatient] = useState(null);
    const [lastCalledPatientId, setLastCalledPatientId] = useState(null);
    const [audioQueue, setAudioQueue] = useState([]); // Cola de reproducción
    const [isPlaying, setIsPlaying] = useState(false); // Estado de reproducción
    const [marqueeMessage, setMarqueeMessage] = useState("ACTUALICE SU INFORMACIÓN PERSONAL EN SOME");


    const getFontSizeForName = (name) => {
        if (!name) return '7rem'; // Tamaño predeterminado si no hay nombre
        if (name.length > 30) return '5.3rem'; // Muy largo
        if (name.length > 20) return '7rem'; // Largo medio
        return '7rem' // Normal
    };

    // Maneja la reproducción de sonidos en cola
    useEffect(() => {
        const playAudioQueue = async () => {
            if (!isPlaying && audioQueue.length > 0) {
                setIsPlaying(true); // Marca que un sonido está en reproducción
                const { type, payload } = audioQueue[0]; // Obtiene el primer sonido en cola

                try {
                    if (type === 'alert') {
                        const alertSound = new Audio('alert.mp3'); // Reproduce el sonido de alerta
                        await alertSound.play();
                    } else if (type === 'read') {
                        // Llama a la función de síntesis de voz
                        await readPatientInfo(payload.name, payload.boxName, payload.hallName);
                    }
                } catch (error) {
                    console.error('Error al reproducir sonido:', error);
                } finally {
                    // Remueve el sonido reproducido de la cola
                    setAudioQueue((queue) => queue.slice(1));
                    setIsPlaying(false); // Libera para permitir el siguiente
                }
            }
        };

        playAudioQueue();
    }, [audioQueue, isPlaying]);

    // Maneja la llegada de datos nuevos
    useEffect(() => {
        const eventSource = new EventSource('https://saludquemchi.online/events');
        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (Array.isArray(data)) {
                setPatients(data);
                setLatestPatient(data[0]);

                if (data[0].status === 0 && data[0].id !== lastCalledPatientId) {
                    // Agregar sonidos a la cola
                    setAudioQueue((queue) => [
                        ...queue,
                        { type: 'alert' }, // Sonido de alerta
                        {
                            type: 'read',
                            payload: {
                                name: data[0].name_patient,
                                boxName: data[0].boxName,
                                hallName: data[0].hallName,
                            },
                        },
                    ]);

                    setLastCalledPatientId(data[0].id);
                }
            } else {
                console.log('Los datos recibidos no son un array:', data);
            }
        };

        return () => {
            eventSource.close();
        };
    }, [lastCalledPatientId]);

    // Evento para escuchar actualizaciones del backend
    useEffect(() => {
        const eventSource = new EventSource("https://saludquemchi.online/events");
        
        eventSource.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);

                // Actualiza solo si el mensaje cambia
                if (data.message && data.message !== marqueeMessage) {
                    setMarqueeMessage(data.message);
                }
            } catch (error) {
                console.error("Error al procesar datos SSE:", error);
            }
        };

        return () => {
            eventSource.close();
        };
    }, [marqueeMessage]);

    // Función para leer la información del paciente
    const readPatientInfo = (name, boxName, hallName) => {
        return new Promise((resolve) => {
            if ('speechSynthesis' in window) {
                const text = `Atención, usuario ${name}, presentarse en ${hallName}, ${boxName}`;
                const utterance = new SpeechSynthesisUtterance(text);
                utterance.voice = getRandomSpanishVoice();
                utterance.rate = 0.8;

                // Resolve cuando termine de hablar
                utterance.onend = () => resolve();
                window.speechSynthesis.speak(utterance);
            } else {
                console.warn('La síntesis de voz no está soportada en este navegador.');
                resolve(); // Asegura que continúa la cola
            }
        });
    };

    // Obtiene una voz aleatoria en español
    const getRandomSpanishVoice = () => {
        const voices = window.speechSynthesis.getVoices();
        const latinAmericanVoices = voices.filter(voice =>
            voice.lang.startsWith('es-CL') && voice.lang !== 'es-ES'
        );
        if (latinAmericanVoices.length > 0) {
            const randomIndex = Math.floor(Math.random() * latinAmericanVoices.length);
            return latinAmericanVoices[randomIndex];
        }
        return null; // No hay voces en español disponibles
    };
    useEffect(() => {
        if (window.speechSynthesis.getVoices().length === 0) {
            window.speechSynthesis.onvoiceschanged = () => {
                getRandomSpanishVoice(); // Inicializa las voces
            };
        }
    }, []);

    return (
        <div className="patients-container">
            {latestPatient && (
                <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                    <h1 style={{ fontSize: '3rem', margin: '20px', padding: '1px' }}>Último paciente</h1>
                    <h1 style={{ fontSize: getFontSizeForName(latestPatient.name_patient), margin: '5px 0', padding: '0' }}>
                        {latestPatient.name_patient}
                    </h1>
                    <h2 style={{ fontSize: '4rem', margin: '5px 0', padding: '0' }}>
                        {latestPatient.hallName} - {latestPatient.boxName}
                    </h2>
                </div>
            )}

            <table style={{ width: '90%', borderCollapse: 'separate', borderSpacing: '8px 8px', margin: '0 auto' }}>
                <thead>
                    <tr>
                        <th style={{ padding: '8px', fontSize: '1.6rem', backgroundColor: 'transparent' }}>Nombre</th>
                        <th style={{ padding: '8px', fontSize: '1.6rem', backgroundColor: 'transparent' }}>Primer llamado</th>
                        <th style={{ padding: '8px', fontSize: '1.6rem', backgroundColor: 'transparent' }}>Repetición de llamado</th>
                        <th style={{ padding: '8px', fontSize: '1.6rem', backgroundColor: 'transparent' }}>Box</th>
                        <th style={{ padding: '8px', fontSize: '1.6rem', backgroundColor: 'transparent' }}>Pasillo</th>
                        <th style={{ padding: '8px', fontSize: '1.6rem', backgroundColor: 'transparent' }}>Estado</th>
                    </tr>
                </thead>
                <tbody>
                    {patients.map(patient => {
                        const formattedHour = patient.hour.split(':').slice(0, 2).join(':');
                        const currentFormattedHour = patient.repeat_call.split(':').slice(0, 2).join(':');
                        return (
                            <tr key={patient.id} style={{ backgroundColor: 'transparent' }}>
                                <td style={{ padding: '8px', fontSize: '1.6rem', fontWeight: 'bold', textAlign: 'center', borderRadius: '10px' }}>{patient.name_patient}</td>
                                <td style={{ padding: '7px', fontSize: '1.6rem', fontWeight: 'bold', textAlign: 'center', borderRadius: '10px' }}>{formattedHour}</td>
                                <td style={{ padding: '7px', fontSize: '1.6rem', fontWeight: 'bold', textAlign: 'center', borderRadius: '10px' }}>{currentFormattedHour}</td>
                                <td style={{ padding: '7px', fontSize: '1.6rem', fontWeight: 'bold', textAlign: 'center', borderRadius: '10px' }}>{patient.boxName}</td>
                                <td style={{ padding: '7px', fontSize: '1.6rem', fontWeight: 'bold', textAlign: 'center', borderRadius: '10px' }}>{patient.hallName}</td>
                                <td style={{
                                    padding: '8px',
                                    fontSize: '1.6rem',
                                    backgroundColor: patient.status === 0 ? 'dodgerblue   ' : patient.status === 1 ? 'green' : 'grey',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    borderRadius: '20px',
                                    width: '250px',
                                    margin: '0 auto'
                                }}>
                                    {patient.status === 0 ? 'Llamado a box' : patient.status === 1 ? 'Se presentó' : 'Diríjase a SOME'}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
             </table>

            <div className="marquee-container">
            <p className="marquee">{ marqueeMessage }</p>
            </div>
        </div>
    );
};

export default Patients;